import React from 'react';
import {StaticImage} from 'gatsby-plugin-image'
import './usp.scss'

export default function Usp({usp1, usp2, usp3}){

    return <>
    <h2 className="usp__title">Wat regelen wij voor u?</h2>
    <div className="container grid usp">
        <div className="usp-item">
            <div className="usp-item--image">
                <StaticImage height={80} src={`../../images/promotion.png`} placeholder="none" style={{maxHeight: '100%'}} imgStyle={{objectFit: 'contain'}}/>
            </div>
            <h2>SEO optimalisatie</h2>
            <p>Met onze top kennis in het Google algoritme helpen we jouw website naar de eerste plaats.</p>
        </div>
        <div className="usp-item">
            <div className="usp-item--image">
                <StaticImage height={80} src={`../../images/responsive.png`} placeholder="none" style={{maxHeight: '100%'}} imgStyle={{objectFit: 'contain'}}/>
            </div>
            <h2>Op alle toestellen</h2>
            <p>Bij ons bent u zeker dat uw website straalt op alle mogelijke toestellen.</p>
        </div>
        <div className="usp-item">
            <div className="usp-item--image">
                <StaticImage height={80} src={`../../images/presentation.png`} placeholder="none" style={{maxHeight: '100%'}} imgStyle={{objectFit: 'contain'}}/>
            </div>
            <h2>Uw zaak en opbrengst centraal</h2>
            <p>Uw omzet verhogen en klantenbestand vergroten, dat is onze prioriteit.</p>
        </div>
    </div>
    </>
}