import React, {useState} from 'react'
import './alert.scss'

export default function Alert({location}){

    const [showAlert, setShowAlert] = useState(0);

    if(location.search.includes("send_message") && showAlert === 0){
      setShowAlert(1);
  
      setTimeout(() => {
        setShowAlert(2);
      }, 5000)
    }

    return(
        <div className={showAlert===1?"alert active":"alert"}>
            We hebben je bericht ontvangen, we laten je zo snel mogelijk iets weten.
        </div>
    )
}