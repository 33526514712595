import React, {useEffect, useState} from 'react'
import {Link, navigate} from 'gatsby';
import "./header.scss"


export default function Header({scrollToAbout, scrollToContact, data, landingPage, notHomePage}){
    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
        const onScroll = e => {
            setScrollTop(e.target.documentElement.scrollTop);
        };
        window.addEventListener("scroll", onScroll);
    
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);


    let menu;
    if(notHomePage || landingPage){
        menu =  <ul class="header-menu">
        <li class="header-menu-item not-mobile"><Link to={"/#about"}><div class="header-menu-item" role="button" tabIndex='0'>Over ons</div></Link></li>
        {/* <li class="header-menu-item not-mobile"><div class="header-menu-item" role="button" tabIndex='0' onClick={() => navigate('/blog')} onKeyDown={() => navigate('/blog')}>Blog</div></li> */}
        {!landingPage &&
        <li class="header-menu-item"><Link to={"/#contact"}><div class="header-menu-item button" role="button" tabIndex='0'>Contacteer ons</div></Link></li>}
        </ul>
    }else{
        menu =  <ul class="header-menu">
        <li class="header-menu-item not-mobile"><div class="header-menu-item" role="button" tabIndex='0' onClick={scrollToAbout} onKeyDown={scrollToAbout}>Over ons</div></li>
        {/* <li class="header-menu-item not-mobile"><div class="header-menu-item" role="button" tabIndex='0' onClick={() => navigate('/blog')} onKeyDown={() => navigate('/blog')}>Blog</div></li> */}
        {!landingPage &&
        <li class="header-menu-item"><div class="header-menu-item button" role="button" tabIndex='0' onClick={scrollToContact} onKeyDown={scrollToContact}>Contacteer ons</div></li>}
        </ul>
    }

    return (
        <header className={scrollTop>80?"header filled":landingPage?"header white":"header"} id="header">
            <div class="header-flex">
                <a class="header-left" onClick={() => navigate("/")} onKeyDown={() => navigate("/")}>
                    <div className="header-left--title">Toolmakers</div>
                </a>
                <div class="header-right">
                    {menu}
                </div>
            </div>
        </header>
    )
}
