import React from 'react';
import {StaticImage} from 'gatsby-plugin-image'
import './whoami.scss'

export default function WhoAmI({reference, scrollToContact, diederik}){
    return <div id="about" ref={reference} className="whoami">
    <div className="container whoami-grid">
        <div class="whoami-image">
            <StaticImage src="../../images/me3.jpg" placeholder="none" alt="Diederik Mathijs" />
        </div>
        <div>
            <div class="whoami-title">Wie zijn we?</div>
            <p>
            Toolmakers is een web- en softwareontwikkelingsbedrijf uit Kortrijk, opgericht in 2019 door Diederik Mathijs. Met zijn jarenlange ervaring 
            en succesvolle deelname in grote software projecten staat hij paraat om u met zijn kennis te helpen.
            </p><p>
            Zijn cutting-edge kennis in webtechnologieën en marketing zorgen er voor dat u bij Toolmakers aan het juiste adres bent voor zowel uw website als software.
            </p>
            <p>
                Naast Diederik staat nog een leger van freelance consultants paraat om specifieke uitdagingen aan te gaan zoals: logo design, brand development,  enz... 
            </p>
        </div>
    </div>
    </div>
}