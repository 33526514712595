import React from "react"
import Abonnementen from "../components/abonnementen/abonnementen"
import Alert from "../components/alert/alert"
import Contact from "../components/contact/contact"
import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Jumbotron from "../components/jumbotron/Jumbotron"
import Usp from "../components/usp/usp"
import WhoAmI from "../components/whoami/whoami"
import { Helmet } from "react-helmet"

export default function Home({location, data}) {

  const ref = React.createRef();
  const contactRef = React.createRef();

  const scrollToAbout = () => ref.current.scrollIntoView() 
  const scrollToContact = () => contactRef.current.scrollIntoView();

  return <>
    <Helmet htmlAttributes={{
      lang: 'nl',
    }}>
      <meta charSet="utf-8" />
      <title>Toolmakers: Uw partner in webontwikkeling in Kortrijk</title>
      <meta name="description" content="Toolmakers is een webdesign en marketing bedrijf uit kortrijk, wij bouwen websites om uw bedrijf online een aanwezigheid te geven." />
    </Helmet>
    <Alert location={location} />
    <Header scrollToAbout={scrollToAbout} scrollToContact={scrollToContact} />
    <Jumbotron />
    <Usp />
    <WhoAmI reference={ref} scrollToContact={scrollToContact} />
    <Abonnementen scrollToContact={scrollToContact} />
    <Contact reference={contactRef} />
    {/* <Footer /> */}
    <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/20448440.js"></script>
    </>
}

