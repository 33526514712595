import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import "./jumbotron.scss"

export default function Jumbotron({ image }) {

    return <><div id="jumbotron" className="jumbotron">
        <div className="jumbotron-text container">
            <div>
            <h1 className="jumbotron-title">
                Zet jouw bedrijf online op de kaart voor de prijs van een netflix abonnement.
            </h1>
                <h2 className="jumbotron-subtitle">
                    Wij bouwen hoogkwalitatieve websites voor minder dan 15 euro per maand.
                    </h2>
            </div>
            <div className="jumbotron__image">
                <StaticImage src="../../images/jumbotron2.jpg" placeholder="none" style={{width:"425px", height:"425px",zIndex:0}}/>
            </div>
        </div>
    </div>
    <div className="jumbotron__tagline">
        {/* 25 bedrijven gingen u voor, contacteer ons vandaag en krijg binnen de dag uw website online. */}
    </div>
    </>
}