import React from 'react'
import './contact.scss'
import { useState } from 'react'
import { navigate } from "gatsby";

export default function Contact({ map, reference, theme }){


    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [message, setMessage] = useState("");

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }
    

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", name, "phonenumber":phoneNumber, email, company, message
            })
        })
        .then(() => navigate("?send_message=true"))
        .catch(error => console.log(error));
    };
    
    return(<>
    <div id="contact" className={`contact ${theme ? `contact-${theme}`:''}`} ref={reference}>
        <div class="contact-background">
            {/* <Img fluid={map.childImageSharp.fluid} style={{position:"absolute",top:0,left:0, width:"100%", height:"400px",zIndex:0, verticalAlign:"top"}} /> */}
        </div>
        <div class="contact-box">
            <h2 className="contact-box__title">Contacteer ons</h2>
            {/* <p>Laat ons iets weten of maak een afpraak.</p> */}
            <div class="contact-box-info">
                <div className="contact-box-info-left">
                    <h2>Stuur ons een bericht</h2>
                    <form className="contact-form" name="contact" data-netlify="true" onSubmit={handleSubmit}>
                        <table>
                            <tr>
                                <td>
                                    <input className="contact-form-input" type="text" id="name" name="name" placeholder="Naam" value={name} onChange={ev => setName(ev.target.value)} />
                                </td>
                                <td>
                                    <input className="contact-form-input" type="text" id="phonenumber" name="phonenumber" placeholder="Telefoonnummer" value={phoneNumber} onChange={ev => setPhoneNumber(ev.target.value)} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input className="contact-form-input" type="email" id="email" name="email" placeholder="E-mailadres" value={email} onChange={ev => setEmail(ev.target.value)} />
                                </td>
                                <td>
                                    <input className="contact-form-input" type="text" id="company" name="company" placeholder="Bedrijfsnaam" value={company} onChange={ev => setCompany(ev.target.value)} />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <label className="contact-form-label" for="message">Vraag/opmerking
                                    <input className="contact-form-input" type="text" id="message" name="message" value={message} onChange={ev => setMessage(ev.target.value)} />
                                    </label>
                                </td>
                            </tr>
                        </table>
                        <input className="contact-form-submit" type="submit" value="Versturen"></input>
                    </form>
                </div>
                <div className="contact-box-info-right">
                    <h2>Contact Informatie</h2>
                    <table>
                        <tr>
                            <td>Adres:</td>
                            <td>Zondernaamstraat 14<br></br> 9070 Heusden</td>
                        </tr>
                        <tr>
                            <td>Telefoon:</td>
                            <td>(+32) 476 77 25 30</td>
                        </tr>
                        <tr>
                            <td>E-mail:</td>
                            <td><a href="mailto:info@toolmakers.be">info@toolmakers.be</a></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    </>)
}