import { Link } from 'gatsby';
import React from 'react';
import './abonnementen.scss'

export default function Abonnementen({scrollToContact}){
    return <div className="abonnementen">
    <div class="container">
        <h2>Onze Formules</h2>
    </div>
    <div className="container abonnementen-flex">
        <div className="abonnementen-flex-item">
            <div className="abonnementen-flex-item-title">
                Website voor Kapsalon
            </div>
            <div className="abonnementen-flex-item-flex">
                <div className="abonnementen-flex-item-flex-full">
                    <div>vanaf €19,99/maand</div>
                    <ul className="abonnementen-voordelen">
                        <li><b>Ongelimiteerd</b> aantal bezoekers</li>
                        <li>HTTPS beveiliging</li>
                        <li>1 e-mailadres</li>
                        <li>1 uur support</li>
                        <li>SEO optimized</li>
                        <li>Domein naam inbegrepen</li>
                    </ul>
                </div>
                <Link to="/kapsalon">
                    <div>
                        <div className="abonnementen-action-button" onClick={scrollToContact}>Bekijk voorbeeld</div>
                    </div>
                </Link>
            </div>
        </div>
        <div className="abonnementen-flex-item">
        <div className="abonnementen-flex-item-title">
                Iets anders?
            </div>
            <div className="abonnementen-flex-item-flex">
                <div className="abonnementen-flex-item-flex-full">
                    <div className="abonnementen-anders">
                        Ben je op zoek naar een oplossing op maat?
                        Wij kunnen je helpen! Contacteer ons en we helpen je graag verder.
                    </div>
                </div>
                <div>
                    <div className="abonnementen-action-button" onClick={scrollToContact}>Contacteer ons</div>
                </div>
            </div>
        </div>
    </div>
    </div>
}